.data-panel-loading-container {
    height: 100vh;
}
.data-panel-container-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.data-panel-container-left {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.data-panel-container-right {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.data-panel {
    background-color: #fff m !important;
}
.rs-theme-dark .data-panel {
    background-color: transparent !important;
}

.data-panel-header {
    padding-right: 20px;
}
.data-panel-title {
    color: var(--rs-text-heading);
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.11111111;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.data-panel-body {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 20px;
    padding-bottom: 33px;
    --rs-scrollbar-bg: initial;
    --rs-scrollbar-thumb-bg: initial;
    --rs-scrollbar-thumb-hover-bg: initial;
    scrollbar-color: initial;
    scrollbar-width: initial;
}
.data-panel-body.no-title {
    margin-top: 0px !important;
}

.data-panel-body .rs-grid-container-fluid .rs-row:not(:last-child) .rs-col {
    margin-bottom: 15px !important;
}

.data-panel-body .rs-grid-container-fluid .rs-row:last-child .rs-col:not(:last-child) {
    margin-bottom: 15px !important;
}

.data-panel-footer-divider {
    margin: 0px !important;
    margin-bottom: 15px !important;
}

.data-panel-footer {
    border-top: none;
    text-align: right;
}

.data-panel-loader-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 80%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-panel-loader-message {
    font-size: 18px;
    font-weight: bold;
    margin-left: 15px;
    color: var(--rs-gray-200);
}

.data-panel-title-id-value {
    padding: 3px 10px !important;
}

.data-panel-title-divider {
    margin-top: 18px !important;
}
