.contato-list-container {
    height: 100px !important;
    overflow-y: auto !important;
    padding: 0 10px !important;
    background-color: var(--rs-input-bg) !important;
}
.contato-list-container .rs-list-item {
    background-color: var(--rs-input-bg) !important;
}
.contato-list-container .rs-list-item:hover {
    background-color: var(--rs-list-hover-bg) !important;
}

.contato-list-item-container {
    display: flex;
}
.contato-list-item-data-container {
    display: block;
    width: calc(100% - 70px);
    max-width: calc(100% - 70px);
}
.contato-list-content-container {
    display: flex;
    width: 100%;
}
.contato-list-content-nome {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}
.contato-list-content-telefone {
    min-width: 120px;
    white-space: nowrap;
}
.contato-list-content-email {
    min-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.contato-list-content-obs {
    min-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contato-list-button-container {
    display: flex;
    padding-right: 2px;
    width: 70px;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
