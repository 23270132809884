.rs-perfil-checktree {
    border: 1px solid var(--rs-border-primary) !important;
    border-radius: 6px !important;
    padding: 3px !important;
}

.perfil-checktree-item {
    display: flex !important;
    gap: 10px !important;
}

.perfil-checktree-item img {
    width: 20px !important;
}

.perfil-row-expanded-content {
    max-height: 100%;
    overflow: auto;
}

.perfil-row-expanded-content .marcado {
    color: #f00;
}
