.alert-drawer-header {
    border: unset !important;
    justify-content: space-between !important;
}

@media (max-width: 578px) {
    .alert-drawer-header {
        padding: 20px !important;
    }
}

.alert-message-container {
    white-space: break-spaces;
    line-height: 1.25;
}
