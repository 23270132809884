.page-404-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 50px;
}

.page-404-content img {
    width: 90%;
    max-height: 450px;
}
