.usuario-popover-tarefas {
    max-width: 300px;
}

.usuario-popover-tarefas-content {
    max-height: 150px;
    overflow-y: auto;
    scrollbar-width: thin;
}

.usuario-popover-tarefas-content::-webkit-scrollbar {
    width: 6px; /* Largura do scrollbar */
    height: 6px; /* Altura do scrollbar horizontal */
}

.usuario-popover-tarefas-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Cor do thumb do scrollbar */
    border-radius: 3px; /* Borda do thumb */
}

.usuario-popover-tarefas-content::-webkit-scrollbar-track {
    background-color: #f2f2f2; /* Cor do track do scrollbar */
}
