* {
  margin: 0;
  padding: 0;
  font-family: "Lato";
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
}

@media screen and (max-width: 576px) {
  div::-webkit-scrollbar {
    width: 5px;
  }
}

::-webkit-scrollbar-thumb {
  background: var(--rs-gray-600);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--rs-gray-800);
}

.rs-btn.rs-btn-ghost:hover {
  transform: scale(1.05);
  color: #fff;
}
.rs-btn.rs-btn-ghost:active {
  transform: scale(0.95);
}
.rs-btn.rs-btn-ghost.rs-btn-red:hover {
  background-color: var(--rs-red-400);
}
.rs-btn.rs-btn-ghost.rs-btn-red:active {
  background-color: var(--rs-red-500);
}
.rs-btn.rs-btn-ghost.rs-btn-green:hover {
  background-color: var(--rs-green-400);
}
.rs-btn.rs-btn-ghost.rs-btn-green:active {
  background-color: var(--rs-green-500);
}
.rs-btn.rs-btn-ghost.rs-btn-blue:hover {
  background-color: var(--rs-blue-400);
}
.rs-btn.rs-btn-ghost.rs-btn-blue:active {
  background-color: var(--rs-blue-500);
}
.rs-btn.rs-btn-ghost.rs-btn-orange:hover {
  background-color: var(--rs-orange-400);
}
.rs-btn.rs-btn-ghost.rs-btn-orange:active {
  background-color: var(--rs-orange-500);
}
.rs-btn.rs-btn-ghost.rs-btn-yellow:hover {
  background-color: var(--rs-yellow-400);
}
.rs-btn.rs-btn-ghost.rs-btn-yellow:active {
  background-color: var(--rs-yellow-500);
}
.rs-btn.rs-btn-ghost.rs-btn-cyan:hover {
  background-color: var(--rs-cyan-400);
}
.rs-btn.rs-btn-ghost.rs-btn-cyan:active {
  background-color: var(--rs-cyan-500);
}
.rs-btn.rs-btn-ghost.rs-btn-violet:hover {
  background-color: var(--rs-violet-400);
}
.rs-btn.rs-btn-ghost.rs-btn-violet:active {
  background-color: var(--rs-violet-500);
}

.align-bottom {
  display: flex !important;
  align-items: flex-end !important;
}

.align-right {
  display: flex !important;
  justify-content: flex-end !important;
}

@media (min-width: 768px) {
  .align-bottom {
    min-height: 60px;
  }
}

.form-group-inline {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.toggle-red-green.rs-toggle-checked .rs-toggle-presentation {
  background-color: rgb(65, 175, 65) !important;
}
.toggle-red-green.rs-toggle-checked .rs-toggle-presentation:hover {
  background-color: rgb(0, 145, 0) !important;
}
.toggle-red-green .rs-toggle-presentation {
  background-color: rgb(245, 65, 65) !important;
}
.toggle-red-green .rs-toggle-presentation:hover {
  background-color: rgb(215, 0, 0) !important;
}

.bg-gradient-orange {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%);
}
.bg-gradient-red {
  background: linear-gradient(87deg, #f5365c 0, #f56036 100%);
}
.bg-gradient-green {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%);
}
.bg-gradient-blue {
  background: linear-gradient(87deg, #11cdef 0, #1171ef 100%);
}

.bold {
  font-weight: bold !important;
}
.red {
  color: red !important;
}
