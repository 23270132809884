.data-modal-body {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    padding-bottom: 33px !important;
}

.data-modal-body .rs-grid-container-fluid .rs-row:not(:last-child) .rs-col {
    margin-bottom: 15px !important;
}

.data-modal-body .rs-grid-container-fluid .rs-row:last-child .rs-col:not(:last-child) {
    margin-bottom: 15px !important;
}

.data-modal-footer-divider {
    margin: 0px !important;
    margin-bottom: 15px !important;
}

.data-modal-loader-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 80%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-modal-loader-message {
    font-size: 18px;
    font-weight: bold;
    margin-left: 15px;
    color: var(--rs-gray-200);
}

.data-modal-title-id-value {
    padding: 3px 10px !important;
}

.data-modal-title-divider {
    margin-top: 18px !important;
}

.data-modal .rs-modal-header-close.rs-btn-close {
    margin-top: 7px !important;
}
