.data-container {
    padding: 20px !important;
}

.data-container-header {
    display: block;
}

.data-container-title {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
}

.data-container-breadcrumb {
    margin-top: 5px !important;
}
