.container-recupera-senha {
    height: 100vh;
    background-color: #eee; /* For browsers that do not support gradients */
    background-image: linear-gradient(#bbb, #eee);
    display: flex;
    align-items: center;
    padding-top: 120px;
}

.container-recupera-senha-panel {
    background-color: #f3f3f3;
    max-width: 450px;
}

.container-recupera-senha .data-panel-recupera-senha {
    padding: 30px;
    background-color: #fff;
}

.container-logo-recupera-senha-client {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    border-bottom: 1px solid #d0d0d0;
}

.container-logo-recupera-senha img {
    max-width: 100%;
    max-height: 115px;
}

.container-logo-recupera-senha-sistemprag {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0 10px 0;
    border-top: 1px solid #d0d0d0;
}

.container-logo-recupera-senha-sistemprag img {
    width: 80%;
}

.recupera-senha-title {
    text-align: center;
    margin-bottom: 25px;
    color: #f00;
}
