.container-login {
    height: 100vh;
    width: 100%;
    background-color: #eee; /* For browsers that do not support gradients */
    background-image: linear-gradient(#bbb, #eee);
    position: relative;
}

.container-login-panel {
    background-color: #f3f3f3;
    max-width: 450px;
    width: 90%;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.container-login .data-panel-login {
    padding: 30px;
    background-color: #fff;
}

.container-logo-login-client {
    width: 100%;
    height: 156px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #d0d0d0;
}

.container-logo-login-client img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.container-logo-login-sistemprag {
    width: 100%;
    height: 98px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 10px 0;
    border-top: 1px solid #d0d0d0;
}

.container-logo-login-sistemprag img {
    width: 80%;
    object-fit: contain;
}
