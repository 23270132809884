.popover-message-container .rs-popover {
    background-color: var(--rs-form-errormessage-bg) !important;
}

.popover-message-container .rs-popover-arrow::after {
    border-bottom-color: var(--rs-form-errormessage-bg) !important;
}

.popover-message-content-container {
    margin: -11px -6px -22px -6px !important;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
}

.popover-message-error {
    color: var(--rs-form-errormessage-text) !important;
}
