.contato-cadastro-modal-backdrop {
    z-index: 9999 !important;
    width: 100% !important;
}

.contato-cadastro-modal .rs-grid-container-fluid .rs-row:not(:last-child) .rs-col {
    margin-bottom: 15px !important;
}

.contato-cadastro-modal .rs-grid-container-fluid .rs-row:last-child .rs-col:not(:last-child) {
    margin-bottom: 15px !important;
}
