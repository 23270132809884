.data-table-container-center {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.data-table-container-left {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.data-table-container-right {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.data-table-panel {
    background-color: #fff m !important;
}
.rs-theme-dark .data-table-panel {
    background-color: transparent !important;
}

.data-table-panel-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.data-table-panel-search {
    display: flex;
    align-items: center;
    gap: 10px;
}

.data-table-panel-search .rs-input-group {
    width: 300px !important;
}

@media (max-width: 589px) {
    .data-table-panel-header {
        flex-direction: column;
        align-items: start;
        gap: 10px;
    }

    .data-table-panel-search,
    .data-table-panel-search .rs-input-group {
        width: 100% !important;
    }
}

.avatar-perfil-200 {
    height: 200px !important;
    width: 200px !important;
    border: solid 1px #ccc;
}
.avatar-perfil-300 {
    height: 300px !important;
    width: 300px !important;
    border: solid 1px #ccc;
}
.avatar-perfil-200 img,
.avatar-perfil-300 img {
    height: fit-content !important;
    width: 100% !important;
}

.data-table-column-button {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
}

.data-table-column-display-button {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: center !important;
}

.data-table-column-edit-button {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: flex-start !important;
}

.data-table-column-delete-button {
    display: flex !important;
    width: 100% !important;
    align-items: center !important;
    justify-content: flex-end !important;
}

.data-table-panel .rs-pagination-group-limit div[role="combobox"] {
    width: 110px !important;
}
