.assinatura-modal-backdrop {
    z-index: 9999 !important;
    width: 100% !important;
}

.assinatura-modal {
    width: calc(100% - 40px) !important;
}

@media screen and (max-width: 992px) {
    .assinatura-modal {
        width: 100% !important;
    }
}

.assinatura-container {
    max-width: 100% !important;
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;
    border: 1px solid var(--rs-border-primary);
    border-radius: 6px;
    padding: 10px;
    background-color: var(--rs-input-bg) !important;
}

.assinatura-container img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    background-color: var(--rs-input-bg) !important;
}

.assinatura-buttons-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    margin: 5px;
    gap: 3px;
    width: 110px;
}

.assinatura-button-waiting {
    height: 36px !important;
}

.assinatura-canvas {
    width: 100% !important;
    height: 500px;
    border: 1px solid var(--rs-border-primary) !important;
    border-radius: 6px !important;
    padding: 3px !important;
    background-color: var(--rs-input-bg) !important;
}
