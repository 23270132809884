.mapa-modal-backdrop {
    z-index: 99999 !important;
}

.rs-modal-wrapper:has(.mapa-modal-dialog) {
    z-index: 100000 !important;
}

.mapa-modal-dialog {
    width: 70% !important;
}

@media screen and (max-width: 920px) {
    .mapa-modal-dialog {
        width: 100% !important;
    }
}

.mapa-modal-dialog .rs-modal-body {
    height: 750px;
    max-height: 80%;
    margin-top: 0 !important;
}

.mapa-modal-divider {
    margin-top: 5px !important;
    margin-bottom: 10px !important;
}

.mapa-modal-dialog .directions {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    margin: 3px;
    background-color: #0b2545;
    border-radius: 5px;
}

.mapa-modal-dialog .directions .direction-destination,
.mapa-modal-dialog .directions .direction-title {
    display: block;
    font-weight: bold;
    cursor: default;
}

.mapa-modal-dialog .directions .direction-destination {
    color: #fa8900 !important;
    margin-bottom: 3px;
}

.mapa-modal-dialog .directions .direction-title {
    color: #5db7ff !important;
    margin-bottom: 7px;
}

.mapa-modal-dialog .directions .direction-item {
    margin-top: 3px;
    display: block;
    color: #fff !important;
    cursor: pointer;
    padding: 0px 6px;
}

.mapa-modal-dialog .directions .direction-item.selected {
    border: 1px solid #8da9c4;
    border-radius: 5px;
    background-color: #134074 !important;
    padding: 0px 5px;
}

.mapa-modal-dialog .directions .direction-item:not(.selected):hover {
    color: #8da9c4 !important;
}

.mapa-modal-dialog .mapa-marker .rs-text,
.mapa-modal-dialog .mapa-marker.rs-text {
    color: #575757 !important;
}
