.custom-input-date-container {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
}
.custom-input-date-container.block {
    display: block;
}

.custom-input-date-calendar-icon-container {
    padding: 7px 12px !important;
}

.custom-input-date-calendar-icon {
    cursor: pointer !important;
    width: 20px !important;
    height: 20px !important;
}

.custom-input-date-calendar-container {
    width: 280px !important;
    padding: 0px !important;
}

.custom-input-date-calendar .rs-calendar-table-cell-content {
    height: 38px !important;
}
.custom-input-date-calendar .rs-calendar-table-cell.rs-calendar-table-cell-selected {
    border-radius: 10px !important;
    background-color: #136917 !important;
    color: white !important;
}
.custom-input-date-calendar .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    border-radius: 10px !important;
    box-shadow: inset 0 0 0 1px #4caf50 !important;
}
.custom-input-date-calendar .rs-calendar-table-cell-is-today .rs-calendar-table-cell-day {
    height: 25px !important;
    width: 25px !important;
    margin-top: 1px !important;
    padding-top: 3px !important;
}
.custom-input-date-calendar .rs-calendar-table-header-cell:first-child .rs-calendar-table-header-cell-content,
.custom-input-date-calendar .dia-domingo,
.custom-input-date-calendar .dia-feriado {
    color: #f44336 !important;
}

.custom-input-date-calendar .dia-feriado .rs-calendar-table-cell-content .rs-calendar-table-cell-day:first-child {
    display: none;
}

.custom-input-date-calendar-footer {
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    padding-top: 0px;
}

.custom-input-date-calendar-footer.with-time {
    justify-content: space-between;
}

.custom-input-date-select-time-backdrop {
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 80%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-input-date-select-time-container {
    z-index: 11000;
    display: flex;
    flex-direction: row;
    gap: 10px;
    position: absolute;
    top: 0;
    justify-content: center;
}
.custom-input-date-select-time-list-container {
    width: 100px;
}
.custom-input-date-select-time-list-title {
    height: 40px;
    text-align: center;
    align-content: center;
}
.custom-input-date-select-time-list-item {
    padding: 5px 0px !important;
    text-align: center;
    cursor: default;
}
.custom-input-date-select-time-list-item.selected {
    background-color: #136917 !important;
    color: white !important;
}
