.data-modal.cliente-cadastro {
    width: 80% !important;
}

@media screen and (max-width: 920px) {
    .data-modal.cliente-cadastro {
        width: 100% !important;
    }
}

.datatable-cellcontent-cliente-inativo {
    color: var(--rs-color-red);
    /* color: red; */
}

.rs-theme-dark .datatable-cellcontent-cliente-inativo {
    color: var(--rs-red-500);
}
