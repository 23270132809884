.menu-navbar {
	box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.5) !important;
	position: fixed !important;
	left: 0 !important;
	top: 0 !important;
	width: 100% !important;
	height: 56px !important;
	z-index: 100;
}

.menu-logo {
	padding: 5px 10px !important;
}

.menu-logo img {
	height: 100% !important;
}

@media screen and (max-width: 350px) {
	.menu-logo {
		margin: 0px;
		margin-bottom: 10px;
	}
}

.menu-stack {
	padding: 5px 10px !important;
}

.menu-icon-button {
	font-size: 20px !important;
	border: 1px solid #d0d0d0 !important;
}

.rs-theme-high-contrast .menu-icon-button,
.rs-theme-dark .menu-icon-button {
	border: none !important;
}

/* .menu-icon-button:focus {
	color: rgb(0, 137, 228) !important;
} */

.menu-avatar-button {
	padding: 0 !important;
	border-radius: 50% !important;
}

.menu-avatar-button img {
	height: auto !important;
}

.menu-container {
	min-width: 200px;
}

.menu-title {
	padding: 3px 10px !important;
	margin: 0px 5px !important;
	background-color: #e5e5e5 !important;
	font-weight: bold !important;
	border-radius: 5px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.rs-theme-high-contrast .menu-title,
.rs-theme-dark .menu-title {
	background-color: #404040 !important;
}

.menu-item,
.menu-item-panel,
.menu-item .rs-dropdown-item-content {
	display: flex !important;
	align-items: center !important;
	gap: 10px !important;
	color: var(--rs-text-primary) !important;
}

.menu-item-link {
	text-decoration-line: none !important;
}

.menu-item img,
.menu-item-panel img {
	height: 20px !important;
}

.menu-item-panel {
	padding: 8px 12px !important;
}

.menu-item-right {
	margin-left: auto !important;
}

.menu-perfil-panel-info {
	padding: 10px !important;
	margin: 0px 7px 20px 7px !important;
}

.menu-perfil-panel-info-nome {
	margin-left: 15px !important;
	font-size: 1.1rem !important;
	font-weight: bold !important;
}

.menu-perfil-panel-info-cargo {
	margin-left: 15px !important;
	font-size: 0.9rem !important;
}

.menu-perfil-panel-info img {
	height: auto !important;
}

.menu-content-container {
	flex-grow: 1 !important;
	max-height: calc(100vh - 56px) !important;
	overflow-y: auto !important;
	margin-top: 56px !important;
}
